<template>
  <div class="cpt-MMC_Gimbal_S1">
    <div class="hd " v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">飞鹰抛投器</div>
      </div>
            <div class="close cf cp pr10" @click="fn" >关闭</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">预设重量</div>
          <div class="input-box">
            <div class="text-box">{{ height }} 克</div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态</div>
          <div class="input-box">
            <div class="text-box">正常</div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">智能模式</div>
          <div class="input-box">
            <el-switch
              @change="handle_change_lntelligent_mode_ctrl"
              v-model="lntelligent_mode_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">执行动作</div>
          <div class="input-box">
            <el-switch
              @change="handle_change_motor_weight"
              v-model="motor_weight_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">重量（g）</div>
          <div class="input-box">
            <el-input v-model="pre_height" size="mini" placeholder="请输入重量" />
            <el-button @click="height = pre_height" size="mini" type="primary">预设</el-button>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <img src="@/assets/images/observe/006.svg" class="dib w200 h200" alt />
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_S1 } from "../utils";
export default {
  data() {
    return {
      timer: null,
      height: 300,
      pre_height: 300,
      lntelligent_mode_status: false,
      motor_weight_status: false,
    };
  },
  methods: {
    fn(){
      this.$emit('close')
    },
    handle_change_lntelligent_mode_ctrl(value) {
      let buffer = MMC_Gimbal_S1.lntelligent_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_motor_weight(value) {
      if(value){
     this.$confirm("是否去确认执行抛下动作？", "确认", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        customClass: "uav_controlPane",
        showClose: false,
      })
        .then(async () => {
          let buffer = MMC_Gimbal_S1.motor_weight(value);
          console.log(buffer,'buffer');
         this.commit_directive(buffer);
         setTimeout(() => {
          this.motor_weight_status= false
         }, 3000);
            this.$message({
            type: "success",
            message: "操作成功",
          });
        })
        .catch(() => {
          this.motor_weight_status= false
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
      }
 
    },
    commit_directive(buffer) {
      this.$emit("directive", buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case "up":
          buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(1);
          this.commit_directive(buffer);
          break;
        case "right":
          // _this.gimbal_yaw_ctrl(1);
          break;
        case "left":
          // _this.gimbal_yaw_ctrl(-1);
          break;
        case "down":
          buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(-1);
          this.commit_directive(buffer);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer);
      //  // buffer = gimbal_pitch_ctrl(0, this.control_speed);
      //     // this.commit_directive(buffer);
      // }, 500);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case "up":
              buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(1);
              this.commit_directive(buffer);
              break;
            case "right":
              // _this.gimbal_yaw_ctrl(1);
              break;
            case "left":
              // _this.gimbal_yaw_ctrl(-1);
              break;
            case "down":
              buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(-1);
              this.commit_directive(buffer);
          }
        },
        onend: () => {
          // buffer = gimbal_pitch_ctrl(0, this.control_speed);
          // this.commit_directive(buffer);
        },
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_S1 {
  position: absolute;
  top: 0px;
  right: 0;
  /* background: center url("~@/assets/images/observe/fckernel/mount/01_bg.png")
    no-repeat; */
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  background-size: 100% 100%;
  width: 500px;
  height: 290px;
  box-sizing: border-box;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg,
            #e3aa77 0%,
            #f5cda9 38%,
            #f9ecd3 58%,
            #fcdbb1 79%,
            #edb07a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
    }
  }
  .bd {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    .form-wrap {
      flex-shrink: 0;
      .form-item {
        display: flex;
        align-items: center;
        height: 40px;
        .label-box {
          color: #cbd0eb;
          width: 75px;
        }
        .input-box {
          display: flex;
          align-items: center;
          .text-box {
            color: #dce9ff;
          }
        }
      }
    }
    .rocker-wrap {
      flex: 1;
      .title-box {
        .title {
          color: #cbd0eb;
        }
      }
      .rocker {
        margin: 10px auto 0;
        width: 154px;
        height: 154px;
        background: -0.5px 3.5px url("~@/assets/images/accident/operation.png")
          no-repeat;
        background-size: 100% calc(100% - 3.5px);
        position: relative;
        .shangUp {
          position: absolute;
          left: 34%;
          top: 0px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          border: 0px solid red;
          cursor: pointer;
        }
        .xaiUp {
          position: absolute;
          left: 34%;
          bottom: 0px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;
    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }
  .el-input {
    width: 165px;
    margin-right: 5px;
    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }
  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }
      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
</style>